<template>
  <div class="filepicker-wrapper">
    <div class="select-wrapper">
      <Multiselect
        v-model:value="selected"
        :label="label"
        :multiple="multiple"
        :options="flattenedTree"
        :taggable="multiple"
        :create-option="false"
        :disabled="!filteredTree.content?.length || disabled || loading || error"
        :busy="loading"
        :placeholder="!flattenedTree?.length ? 'No files indexed for this invention' : 'Select file(s) to attach'"
        :tag-open="openTag"
        @update:value="notifyChange"
      />
      <div class="icons">
        <template v-if="filteredTree.content?.length && !disabled && !error">
          <a v-if="hasSelected" class="icon" :href="href" title="Open all selected files" :disabled="disabled || loading" @click.stop="open">
            <Icon v-if="opening" name="loading" spin/>
            <Icon v-else name="open-in-new"
          /></a>
          <Button :disabled="disabled || loading" variant="icon" title="Show folders tree" @click="isModalShown = true"
            ><Icon name="file-tree-outline"
          /></Button>
        </template>
      </div>
      <template v-if="!loading">
        <span v-if="error" class="error-message">An error occured while loading file tree.</span>
        <span v-if="fileMissingError" class="error-message">Some of selected files are missing in files tree. Please check your selection.</span>
      </template>
    </div>
    <Modal :visible="isModalShown && !disabled" class="filepicker-modal" closable @close="closeModal">
      <template #title>Choose file {{ ext?.lenght ? ext : '' }}</template>
      <div class="filepicker-modal">
        <Multiselect v-model:value="localSearch" :options="localSearch" placeholder="Search files" :taggable="true" :multiple="true" />
        <div class="tree-wrapper">
          <div class="root">
            {{ rootPath }}
          </div>
          <div class="tree-holder">
            <file-tree
              v-if="filteredTree.content?.length"
              :node="filteredTree"
              :expanded="true"
              :selected="selected"
              @element-toggled="onElementToggled"
            />
            <div v-else class="no-match">No matching files found</div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="footer">
          <Button v-if="selected?.length" class="attach-button" variant="text" @click="selected = []">Clear</Button>
          <Button class="attach-button" type="button" color="primary" @click="submit">OK</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
function filter(array, text, ext) {
  if (!text.length) {
    return array;
  }

  const lowerCased = Array.isArray(text) ? text.map(a => a.toLowerCase()) : [text.toLowerCase()];
  const getNodes = (result, object) => {
    if (lowerCased.some(w => object.name.toLowerCase().includes(w))) {
      if (ext?.length && object.type === 'file') {
        const splitted = object.name.split('.');
        if (ext.includes(splitted[splitted.length - 1])) {
          result.push(object);
        }
      } else {
        result.push(object);
      }
      return result;
    }

    if (Array.isArray(object.content)) {
      const content = object.content.reduce(getNodes, []);
      if (content.length) result.push({ ...object, content, expanded: true });
    }
    return result;
  };
  const content = array.content.reduce(getNodes, []);
  return {
    ...array,
    content
  };
}

const replace = function(content, treePath) {
  content.forEach(c => {
    if (c.type === 'file') {
      c.path = combine(treePath, c.path);
    } else {
      replace(c.content, treePath);
    }
  });
};

function combine(tree, path) {
  const filePath = atob(path);
  const treePath = atob(tree);
  return btoa(`${treePath}\\${filePath}`);
}

function flatten(array, ext, treePath) {
  return array.reduce((acc, curr) => {
    if (curr.type === 'file') {
      acc.push({
        value: curr.path,
        label: curr.name
      });
    } else {
      const arr = flatten(curr.content, ext, treePath);
      acc.push(...arr);
    }
    return acc;
  }, []);
}

import FileTree from './FileTree.vue';
import Modal from '@/components/common/Modal';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    FileTree,
    Modal,
    Multiselect,
    Button,
    Icon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    search: {
      type: [Array, String],
      default: () => []
    },
    ext: {
      type: Array,
      default: () => []
    },
    reference: {
      type: String,
      retquired: true,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  data() {
    let localSearch;
    if (!this.search) {
      localSearch = [];
    } else {
      localSearch = Array.isArray(this.search) ? this.search : [this.search];
    }
    return {
      tree: null,
      flattenedTree: [],
      isModalShown: false,
      localSearch,
      selected: Array.isArray(this.value) ? this.value.filter(a => a) : this.value,
      loading: false,
      opening: false,
      error: false,
      openTag: {
        action: option => {
          option.executing = true;
          new Promise(resolve => {
            const link = document.createElement('a');
            link.href = `ph://${option.value}`;
            document.body.appendChild(link);
            link.click();
            setTimeout(resolve, 1500);
          }).then(r => {
            option.executing = false;
          });
        },
        icon: 'open-in-new',
        title: 'Click to open file'
      }
    };
  },
  computed: {
    filteredTree() {
      if (!this.tree?.content) {
        return { content: [] };
      }
      return filter(this.tree, this.localSearch, this.ext);
    },
    rootPath() {
      return this.tree && atob(this.tree.path).trim();
    },
    hasSelected() {
      return this.selected?.length;
    },
    href() {
      if (!Array.isArray(this.selected)) {
        return `ph://${this.selected}`;
      }
      return `ph://${this.selected.join('&')}`;
    },
    fileMissingError() {
      const filtered = this.value.filter(path => this.flattenedTree.find(branch => branch.value === path));
      return filtered.length !== this.value.length;
    }
  },
  async created() {
    try {
      this.loading = true;

      const tree = await httpClient.get(`/api/documents-repository-connector/index/${this.reference}`);
      replace(tree.content, tree.path);
      this.tree = tree;
      this.flattenedTree = flatten(this.tree.content, this.ext, this.tree.path).filter(file => {
        if (!this.ext || !this.ext.length) {
          return true;
        }
        const splitted = file.label.split('.');
        const ext = splitted[splitted.length - 1];
        return this.ext.includes(ext);
      });
    } catch (e) {
      if (e.response.status !== 404) {
        this.error = true;
      }

      // lets somehow handle this
      this.tree = {};
      this.flattenedTree = this.value;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onElementToggled(path) {
      const index = this.selected.indexOf(path);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        if (this.multiple) {
          this.selected.push(path);
        } else {
          this.selected = [path];
        }
      }
    },
    submit() {
      this.isModalShown = false;
      if (!this.search) {
        this.localSearch = [];
      } else {
        this.localSearch = Array.isArray(this.search) ? this.search : [this.search];
      }
      this.notifyChange(this.selected);
    },
    closeModal() {
      this.isModalShown = false;
      if (!this.search) {
        this.localSearch = [];
      } else {
        this.localSearch = Array.isArray(this.search) ? this.search : [this.search];
      }
    },
    async open() {
      this.opening = true;
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.opening = false;
    },
    notifyChange(files) {
      const payload = Array.isArray(files) ? [...files] : [files];
      this.$emit('update:value', files ? payload : []);
    }
  }
};
</script>
<style lang="scss" scoped>
.filepicker-wrapper {
  display: flex;
  flex-direction: column;
  .label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }
  .fileRep {
    font-size: 0.7rem;
  }
  .select-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .error-message {
    font-size: 0.75rem;
    color: var(--theme-error);
    margin: 5px 0;
  }
  .icons {
    display: flex;
    align-self: flex-start;
    padding-top: 1.1rem;

    .icon {
      color: var(--theme-on-background);
      padding: 8px;
      padding-right: 0;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.no-match {
  display: inline-flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  font-style: italic;
}
.filepicker-modal .modal {
  min-width: 50%;
  height: 80%;
  grid-template-rows: max-content 1fr max-content;

  .attach-button {
    min-width: 90px;
  }
}
</style>
<style lang="scss" scoped>
.filepicker-modal {
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;
  z-index: 200;
  padding: 2px;

  .modal > div {
    padding-left: 0;
    padding-right: 0;
  }

  .tree-wrapper {
    height: 100%;
    overflow: hidden;
    padding-top: 2px;
    .root {
      font-size: 0.8rem;
      padding: 5px 15px;
      font-style: italic;
      opacity: 0.8;
    }
    .tree-holder {
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
