<template>
  <div class="node-tree">
    <div v-if="node.type === 'directory'" class="label" @click="toggleExpand">
      <div class="icon-group" :style="{ marginLeft: `${depth * 10}px ` }">
        <div class="mark">
          {{ isExpanded ? '-' : '+' }}
        </div>
        <div>
          <Icon name="folder-outline" />
        </div>
      </div>
      {{ node.name }}
    </div>
    <div
      v-else-if="node.type === 'file'"
      class="label file"
      :class="{ selected: selected.includes(node.path) }"
      :title="getTitle(node)"
      @click="$emit('elementToggled', node.path)"
    >
      <div class="icon-group" :style="{ marginLeft: `${depth * 10}px ` }">
        <div class="mark"></div>
        <div>
          <Icon :name="getIcon(node)" />
        </div>
      </div>
      {{ node.name }}
    </div>
    <template v-if="isExpanded">
      <ul v-if="content?.length">
        <node
          v-for="child in content"
          :key="child.path"
          :depth="depth + 1"
          :node="child"
          :selected="selected"
          @element-toggled="$e => $emit('elementToggled', $e)"
        ></node>
      </ul>
      <!-- <div v-else>empty</div> -->
    </template>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
export default {
  name: 'Node',
  components: {
    Icon
  },

  props: {
    depth: {
      type: Number,
      default: 0
    },
    node: {
      type: [Object, Array],
      default: () => []
    },
    expanded: {
      type: Boolean
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  emits: ['elementToggled'],
  data() {
    const content =
      this.node.content
        ?.map(node => ({
          title: this.getTitle(node),
          ...node
        }))
        .sort((a, b) => {
          if (a.type !== b.type) {
            return a.type === 'directory' ? -1 : 1;
          }

          return a.title.localeCompare(b.title);
        }) || [];
    return {
      isExpanded: this.node.expanded || this.expanded,
      content
    };
  },
  watch: {
    'node.expanded'(nw, ow) {
      if (nw && !ow) {
        this.isExpanded = true;
      }
      if (!nw && ow) {
        this.isExpanded = false;
      }
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    getIcon(node) {
      if (node.type === 'directory') {
        return 'folder';
      }

      if (node.name.endsWith('.docx') || node.name.endsWith('.doc')) {
        return 'file-word';
      }

      if (node.name.endsWith('.eml') || node.name.endsWith('.msg')) {
        return 'email';
      }

      if (node.name.endsWith('.pdf')) {
        return 'file-pdf-box';
      }

      if (node.name.endsWith('.vsdx') || node.name.endsWith('.vsd')) {
        return 'drawing-box';
      }

      return 'file';
    },
    getTitle(node) {
      return node && atob(node.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.node-tree {
  .icon-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    .mark {
      padding: 5px;
      width: 10px;
    }
  }
  .label {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin: 1px 0px;
    gap: 5px;
    cursor: pointer;
    &.selected,
    &:hover {
      background-color: var(--theme-surface);
    }
    user-select: none;
  }
  .file {
    padding: 2px 0px;
  }
}
</style>
